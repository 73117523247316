<template>
<div>
    <HeaderPage />

    <transition name="fade" mode="in-out">
        <router-view />
    </transition>
    <div class="toast-container p-3 position-fixed bottom-0">
        <div class="toast border-0 fade show m-3 bg-body" v-if="!cookieAccepted" id="myToast">
            <div class="toast-body">
                <h5 class="me-auto fw-bold"><i class="bi-gift-fill"></i> {{ $t('cookieBanner.title') }}</h5>
                <p v-html="$t('cookieBanner.description')"></p>
                <div class="d-grid gap-2">
                    <button type="button" class="btn btn-outline-dark rounded-0" @click="acceptCookie">{{ $t('cookieBanner.btn') }}</button>
                </div>
            </div>
        </div>
    </div>
    <FooterPage />
</div>
</template>

<script>
import HeaderPage from "@/components/Header.vue";
import FooterPage from "@/components/Footer.vue";
import $ from 'jquery';
export default {
    components: {
        HeaderPage,
        FooterPage,
    },
    data() {
        return {
            cookieAccepted: localStorage.getItem('cookie') === 'true',
            enterTime: null,
        };
    },
    mounted() {
        this.enterTime = new Date();
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    },
    beforeUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },
    methods: {
        handleBeforeUnload() {
            this.$sendViewEvent();
        },
        acceptCookie() {
            this.cookieAccepted = true;
            localStorage.setItem('cookie', 'true');
        }
    },
    watch: {
        '$route'() {
            var hamburger = $('.navbar-collapse');
            if (hamburger && hamburger.length > 0) {
                $('.navbar-collapse').removeClass('show');
            }
        }
    }
};
</script>

<template>
    <div>
        <div class="px-4 text-center">
            <h1 class="text-center mt-5 p-1">{{ $t('guidePage.header') }}</h1>
            <div class="col-lg-6 mx-auto">
                <p class="lead mb-5 fw-normal">{{ $t('guidePage.description') }}</p>
            </div>
        </div>

        <div class="container mb-4 mt-5 mx-auto">
            <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item shadow-sm" v-for="guide in guides" :key="guide.slug">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            :data-bs-target="'#flush-' + guide.id" aria-expanded="false" :aria-controls="'flush-' + guide.id">
                            <span class="mt-3"><h4 class="text-body-emphasis lh-1 mb-3 fw-normal">{{ guide.title }} <a :href="'/Guide/' + guide.slug" target="_blank"><span class="badge text-bg-light ms-2 px-2" id="new-tab"><i class="fa-solid fa-arrow-up-right-from-square fa-2xs"></i></span></a></h4></span>
                        </button>
                    </h2>
                    <div :id="'flush-' + guide.id" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body p-0">
                            <div>
                                <div class="row flex-lg-row-reverse align-items-center p-5">
                                    <div class="col-12 col-sm-12 col-lg-6 mx-auto">
                                        <iframe class="shadow" width="100%" height="315"
                                            :src="guide.youtubeLink + '?modestbranding=0&showinfo=0'"
                                            title="YouTube video player" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowfullscreen></iframe>
                                    </div>
                                    <div class="col-lg-6">
                                        <p class="lead fw-normal" v-for="lead in guide.content" :key="lead.type">{{ lead.context }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GuidePage",
    data() {
        return {
            guides: this.$tm('guidePage.guideElements'),
        }
    }
};
</script>

<style scoped src="../assets/css/guide.css"/>

<template>
<div>
    <div class="px-4 pt-2 text-center">
        <h1 class="text-center mt-5 p-1">{{ guideData?.title }}</h1>
        <div class="col-lg-6 d-flex justify-content-center mx-auto">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item mx-auto"><router-link to="/Guide">{{ $t('guidePage.header') }}</router-link></li>
                    <li class="breadcrumb-item active mx-auto" aria-current="page">{{ guideData?.title }}</li>
                </ol>
            </nav>
        </div>
    </div>

    <div class="container mb-4 mx-auto">
        <div>
            <div class="row flex-lg-row-reverse align-items-center p-5">
                <div class="col-10 col-sm-8 col-lg-6 mx-auto">
                    <iframe class="shadow" width="100%" height="315" :src="guideData?.youtubeLink + '?modestbranding=0&showinfo=0'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div class="col-lg-6">
                    <p class="lead fw-normal" v-for="lead in guideData?.content" :key="lead.type">{{ lead.context }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "GuideDetails",
    data() {
        return {
            guides: this.$tm('guidePage.guideElements'),
            guideData: null,
        }
    },
    mounted() {
        const slug = this.$route.params.slug;
        const guide = this.guides.find(g => g.slug === slug);

        if (guide) {
            this.guideData = guide;
            document.title = guide.title + " - Zerduz";
        } else {
            this.$router.push('/Guide');
        }
    }
};
</script>

<style scoped src="../assets/css/guide.css"/>
